<script setup lang="ts"></script>
<template>
  <HeaderComponent></HeaderComponent>
    <div>
        <ProductsComponent/>
        <MemberComponent/>
    </div>
</template>

<script lang="ts">

import ProductsComponent from '@/components/ProductsComponent.vue';
import MemberComponent from '@/components/MemberComponent.vue';
import HeaderComponent from '@/components/HeaderComponent.vue';


export default {
  name: 'TopComp',
  components: {
    HeaderComponent,
    ProductsComponent,
    MemberComponent,
  },
};
</script>