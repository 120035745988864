<template>
    <!-- Product -->
    <section id="product-section">
    <header>
        <h1>Product</h1>
        <p>プロダクトの説明</p>
    </header>
    <aside>
        <h1 id="product-a-section">Sanskrit Vocabulary app</h1>
        <p><img src="/static/images/sanskrit_icon.png" alt="Sanskrit Vocabulary appの画像"></p>
        <p>サンスクリット学習を少しでも楽にできるような学習アプリ</p>
        
        <router-link :to="{path:'/privacypolicy'}">プライバシーポリシー</router-link>
    </aside>
    <aside>
        <h1 id="product-b-section">coming soon</h1>
        <p></p>
    </aside>
    </section>
</template>