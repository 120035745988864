<template>
  <div v-if="!isLoading" v-html="markdownContent"></div>
  <p v-else>読み込み中...</p> <!-- 読み込み中に表示する内容 -->
  <router-link v-if="!isLoading" :to="{path:'/'}">戻る</router-link>
</template>

<script lang="ts">
import { defineComponent, ref, onMounted } from 'vue';
import { marked } from 'marked';

export default defineComponent({
  setup() {
    const markdownContent = ref(''); // refを使ってリアクティブデータを定義
    const isLoading = ref(true); // 読み込み中かどうかのフラグ

    onMounted(async () => {
      try {
        // fetchでMarkdownファイルを取得
        const response = await fetch('./markDown/privacyPolicy.md');
        const data = await response.text();
        markdownContent.value = await marked(data); // markedでMarkdownをHTMLに変換して代入
      } catch (error) {
        console.error('Failed to load markdown file:', error);
      }finally {
        isLoading.value = false; // 読み込みが完了したらフラグをfalseに
      }

    });

    return {
      markdownContent, // テンプレートで使えるようにreturn
      isLoading
    };
  },
});
</script>
