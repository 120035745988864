import {  createRouter, createWebHistory } from 'vue-router';
import PageTop from "@/views/PageTop.vue";
import PrivacyPolicyComponent from "@/views/PrivacyPolicyPage.vue";

  const routes = [
    {
      path:'/',
      name:'top',
      component:PageTop,
  },
  {
      path:'/privacypolicy',
      name:'privacyPolicy',
      component:PrivacyPolicyComponent,
  },
  ]
  
  const router = createRouter({
    history: createWebHistory('/'),
    routes,
  });

  export default router;