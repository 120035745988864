<template>
    <header id="hero-header">
        <!-- Header -->
        <nav>
            <ul id="header-tab">
                <li><a href="#">Top</a></li>
                <li><a href="#product-section">Product</a>
                    <ul>
                        <li><a href="#product-a-section">Sanskrit Vocabulary app</a></li>
                        <li><a href="#product-b-section">coming soon</a></li>
                    </ul>
                </li>
                <li><a href="#member-section">Members</a></li>
            </ul>
        </nav>

        <!-- Hero-Text -->
        <h1 id="hero-text">H and IT Lab.</h1>
        <p>人文学と情報技術を融合させた研究とプロダクトをおこなっています</p>
        <hr>
    </header>
</template>
