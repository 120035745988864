import { openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createBlock as _createBlock, Fragment as _Fragment } from "vue"

const _hoisted_1 = ["innerHTML"]
const _hoisted_2 = { key: 1 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_router_link = _resolveComponent("router-link")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    (!_ctx.isLoading)
      ? (_openBlock(), _createElementBlock("div", {
          key: 0,
          innerHTML: _ctx.markdownContent
        }, null, 8, _hoisted_1))
      : (_openBlock(), _createElementBlock("p", _hoisted_2, "読み込み中...")),
    (!_ctx.isLoading)
      ? (_openBlock(), _createBlock(_component_router_link, {
          key: 2,
          to: {path:'/'}
        }, {
          default: _withCtx(() => _cache[0] || (_cache[0] = [
            _createTextVNode("戻る")
          ])),
          _: 1
        }))
      : _createCommentVNode("", true)
  ], 64))
}