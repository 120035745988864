import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_HeaderComponent = _resolveComponent("HeaderComponent")!
  const _component_ProductsComponent = _resolveComponent("ProductsComponent")!
  const _component_MemberComponent = _resolveComponent("MemberComponent")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_HeaderComponent),
    _createElementVNode("div", null, [
      _createVNode(_component_ProductsComponent),
      _createVNode(_component_MemberComponent)
    ])
  ], 64))
}