<template>
    <section id="member-section">
        <header>
            <h1>Members</h1>
        </header>
        <aside>
            <ol>
                <li>Kawaguchi Atsushi</li>
                <li>Ikeda Kouichi</li>
            </ol>
        </aside>
    </section>
</template>