<template>
  <footer>
    <p>&copy; 2020-{{currentYear}} H and IT Lab. Humanities and Information Technology</p>
  </footer>
  </template>
  
  <script>
  export default {
    name: 'FooterComponent',
    data(){
        return{
            currentYear:new Date().getFullYear()
        };
    }
  };
  </script>
  
  <style scoped>
 
  footer {
    margin: 0 auto;
    padding: 3rem 1rem;
}
p {
    margin: 0.75rem 0;
    padding: 0;
    width: 100%;
}

  </style>



  